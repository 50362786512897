.klaro {
  font-family: "Arial", sans-serif;
}

.klaro .cookie-modal,
.klaro .cookie-notice {
  background-color: rgba(33, 41, 52, 0.95);
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.klaro .cookie-modal .cm-header,
.klaro .cookie-notice .cn-header {
  background-color: #1a202c;
  padding: 1em;
  border-radius: 8px 8px 0 0;
}

.klaro .cookie-modal .cm-body,
.klaro .cookie-notice .cn-body {
  padding: 1em;
}

.klaro .cookie-modal .cm-footer,
.klaro .cookie-notice .cn-footer {
  background-color: #1a202c;
  padding: 1em;
  border-radius: 0 0 8px 8px;
}

.klaro .cm-btn,
.klaro .cn-btn {
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease;
  padding: 0.5em 1em;
}

.klaro .cm-btn-success,
.klaro .cn-btn-success {
  background-color: #48bb78;
}

.klaro .cm-btn-success:hover,
.klaro .cn-btn-success:hover {
  background-color: #38a169;
}

.klaro .cm-btn-decline,
.klaro .cn-btn-decline {
  background-color: #f56565;
}

.klaro .cm-btn-decline:hover,
.klaro .cn-btn-decline:hover {
  background-color: #e53e3e;
}

.klaro .cm-toggle-slider {
  background-color: #cbd5e0;
}

.klaro .cm-toggle-slider::before {
  background-color: #fff;
}

.klaro input:checked + .cm-toggle-slider {
  background-color: #48bb78;
}

.klaro .cm-list-title,
.klaro .cm-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.klaro .cm-purpose-description,
.klaro .cm-service-description {
  font-size: 0.9em;
  color: #a0aec0;
  margin-bottom: 1em;
}

.klaro .cm-service {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  padding: 1em;
  margin-bottom: 0.5em;
}

.klaro .cm-service:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.cm-powered-by {
  display: none !important;
}
